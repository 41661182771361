import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../shared/config/axios-interceptor";
import { IUser } from "../../../shared/model/user.model";

export interface ILoginForm {
    username: string;
    password: string;
}

export interface IForgotPassword {
    email: string;
}

export interface IChangePassword {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

export interface IResetPassword {
    userName?: string;
    newPassword: string;
    confirmNewPassword: string;
}

const prefixAuth = "auth";

export const login = createAsyncThunk(
    `auth-login`,
    async (body: ILoginForm, thunkAPI) => {
        try {
            const { data } = await axios.post(
                `${prefixAuth}/login`,
                pickBy(body)
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getProfile = createAsyncThunk(
    `get-profile`,
    async (_, thunkAPI) => {
        try {
            const { data } = await axios.get(`${prefixAuth}/profile`);
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const forgotPassword = createAsyncThunk(
    `forgot-password`,
    async (body: IForgotPassword, thunkAPI) => {
        try {
            const { data } = await axios.post(
                `${prefixAuth}/forgot-password`,
                pickBy(body)
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const resetPassword = createAsyncThunk(
    `reset-password`,
    async (body: IResetPassword, thunkAPI) => {
        try {
            const { data } = await axios.put(
                `${prefixAuth}/reset-password-user`,
                pickBy(body)
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const changePassword = createAsyncThunk(
    `change-password`,
    async (body: IChangePassword, thunkAPI) => {
        try {
            const { data } = await axios.put(
                `${prefixAuth}/change-password`,
                pickBy(body)
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateProfile = createAsyncThunk(
    `update-profile`,
    async (body: IUser, thunkAPI) => {
        try {
            const { id } = body;
            const { data } = await axios.put(`profile/${id}`, pickBy(body));
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const firebaseToken = createAsyncThunk(
    `firebase_token_post`,
    async (token: string, thunkAPI) => {
        try {
            const { data } = await axios.post(
                `${prefixAuth}/firebase-token`,
                pickBy({token})
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
