export const SERVER_API_URL = "https://jitter.metasol.vn/api/";
// export const UPLOAD_API_URL = '';
export const UPLOAD_API_URL = "https://jitter.metasol.vn/api/";
export const SYNCFUSION_COMMUNITY_KEY =
  "Ngo9BigBOggjHTQxAR8/V1NHaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fdnVTQ2VcUEZxVkQ=";
export const SOCKET_URL = "wss://jitter-be.metasol.vn";

// export const firebaseConfig = {
//   apiKey: "AIzaSyAMpKDpNVGqM1orLi6sQCJcbTPou4ueL7I",
//   authDomain: "fir-push-notification-bd2e6.firebaseapp.com",
//   projectId: "fir-push-notification-bd2e6",
//   storageBucket: "fir-push-notification-bd2e6.appspot.com",
//   messagingSenderId: "67199471300",
//   appId: "1:67199471300:web:0b8b1af6b5a411be064391",
//   measurementId: "G-MWCFHYM9QT"
// };
export const firebaseConfig = {
  apiKey: "AIzaSyD7hUN6RCvIi35goeWa88zzi9QAgadpkwY",
  authDomain: "hello-test-a7274.firebaseapp.com",
  projectId: "hello-test-a7274",
  storageBucket: "hello-test-a7274.appspot.com",
  messagingSenderId: "351377201423",
  appId: "1:351377201423:web:b04ca9ee6f5e3947a2467a",
  measurementId: "G-YFXLY9DLSD"
};

// export const vapidKeyFirebase = "BFCCNcSsB19re90AxIU4yN7ew_E38bzzVYY9kzQmFDIpLqHKLuTNBCI6OxpsI2oCgxejEWXfS05q3MHPcUdFXds"
export const vapidKeyFirebase = "BK6gUQ2cFNJTZNTrzD3toxNkgD4M_CzlkHXJfzUuOEmme8y4Qmdu5byJ1KT8NcGMwAnA3q4RZg4JwyA0DC2BtUQ"