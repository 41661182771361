import avatar from "@/assets/img/avatar.jpg";
import SendIcon from "@/app/shared/icons/SendIcon";
import { RootState } from "@/reducers";
import { INewPost } from "@/shared/model/post";
import { ITask } from "@/shared/model/task.model";
import { AppDispatch } from "@/store";
import {
    CAvatar,
    CFormInput,
    CInputGroup,
    CInputGroupText,
} from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Comment from "../../Forums/Post/Comment";
import { createEntity, getEntities } from "../../Forums/Post/posts.api";
import {
    fetching,
    postsSelectors,
    resetEntity,
} from "../../Forums/Post/posts.reducer";
import { getEntities as getEntitiesTask } from "../tasksManagement.api";
import { getEntities as getEntitiesProject } from "../../../projectManagement.api";

interface ITaskDiscussTabProps {
    taskObj: ITask;
}

const TaskDiscussTab = ({ taskObj }: ITaskDiscussTabProps) => {
    const { user } = useSelector((state: RootState) => state.authentication);
    const [content, setContent] = useState("");
    const dispatch = useDispatch<AppDispatch>();
    const { initialState } = useSelector(
        (state: RootState) => state.postsReducer
    );
    const { filterState: filterStateTask } = useSelector(
        (state: RootState) => state.tasksReducer.initialState
    );
    const { filterState: filterStateProject } = useSelector(
        (state: RootState) => state.tasksReducer.initialState
    );
    const { filterState, updateEntitySuccess } = initialState;

    const posts = useSelector(postsSelectors.selectAll);

    const createPost = () => {
        if (!content?.trim()) return;
        const newPost: INewPost = {
            projectId: taskObj?.projectId,
            taskId: taskObj?.taskId,
            level: 1,
            content: content?.trim(),
            notificationDto: {
                userId: [user?.id ?? ""],
                typeNotification: "discussion",
                notificationDiscussion: {},
            },
        };
        dispatch(fetching());
        dispatch(createEntity(newPost));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && content?.trim()) {
            createPost();
        }
    };

    useEffect(() => {
        if (updateEntitySuccess) {
            dispatch(resetEntity());
            setContent("");
            dispatch(fetching());
            dispatch(
                getEntities({
                    ...filterState,
                    type: "task",
                    projectId: Number(taskObj.taskId),
                    level: 1,
                    limit: 100,
                })
            );
            dispatch(getEntitiesTask(filterStateTask));
            dispatch(getEntitiesProject(filterStateProject));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateEntitySuccess]);

    useEffect(() => {
        dispatch(fetching());
        dispatch(
            getEntities({
                ...filterState,
                type: "task",
                projectId: Number(taskObj.taskId),
                level: 1,
                limit: 100,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filterState)]);

    return (
        <div className="discuss-tab tab-content" style={{ padding: "32px" }}>
            <div className="comment-input d-flex">
                <div>
                    <CAvatar
                        src={user?.avatar || avatar}
                        className="avatar-32"
                    />
                </div>
                <CInputGroup className="input-start-group ms-2">
                    <CFormInput
                        placeholder="Viết bình luận..."
                        name="content"
                        autoComplete="off"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="text-xs"
                        style={{ borderRadius: "8px 0px 0px 8px" }}
                    />
                    <CInputGroupText
                        onClick={createPost}
                        className="cursor-pointer"
                        style={{ borderRadius: "0px 8px 8px 0px" }}
                    >
                        <SendIcon height={16} width={16} />
                    </CInputGroupText>
                </CInputGroup>
            </div>

            <div className="d-flex d-wrap flex-column" style={{ gap: "30px" }}>
                {posts?.map((post) => (
                    <div className="w-100">
                        <Comment data={post} key={`comment-${post.id}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TaskDiscussTab;
