import {
    CButton,
    CCol,
    CDatePicker,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CInputGroup,
    CInputGroupText,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CRow,
} from "@coreui/react-pro";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";
import axios from "../../../../../shared/config/axios-interceptor";
import { useDispatch, useSelector } from "react-redux";
// import { IPermission } from '../../../../shared/model/permission.model';
import { IUserParams } from "@/app/modules/SystemSetting/UserManagement/usersManagement.api";
import CustomFormikEditor from "@/app/shared/CustomEditor/CustomEditor";
import SModal from "@/app/shared/Modal/SModal";
import FormikMultiFileUpload from "@/app/shared/TaskUploadContainer/FormikMultiFileUpload";
import { ToastSuccess } from "@/app/shared/toast/Toast";
import { RootState } from "@/reducers";
import { ResponseStatus } from "@/shared/enumeration/ResponseStatus";
import {
    TaskCategoryEnum,
    TaskStatusEnum,
    TaskTypeEnum,
    mapTaskCategoryToString,
    mapTaskStatusToString,
    taskCategoryArray,
    taskIdNameDefault,
    taskStatusArray,
} from "@/shared/enumeration/task.enum";
import { IProject } from "@/shared/model/project.modal";
import { INewTask, ITask } from "@/shared/model/task.model";
import { IUser } from "@/shared/model/user.model";
import { ISelectValue } from "@/shared/shared-interfaces";
import {
    checkIsDateAfter,
    checkIsDateBefore,
    handleJsonParseFileArr,
    handleUploadMultipleFile,
} from "@/shared/utils/ultils";
import { AppDispatch } from "@/store";
import dayjs from "dayjs";
import { unionWith } from "lodash";
import { getProjectStaff } from "../../projectManagement.api";
import { createEntity, updateEntity } from "./tasksManagement.api";
import { fetching, resetEntity } from "./tasksManagement.reducer";

interface ITaskUpdateProps {
    visible: boolean;
    project: IProject;
    setVisible: (visible: boolean) => void;
    taskObj?: ITask;
    taskType: TaskTypeEnum;
    detailTask?: string | number;
}

export const returnUserMultipleItem = (
    userItem: IUser[],
    selectUser?: IUser[]
) => {
    if (!userItem) return [];
    if (selectUser) {
        const userSelect = selectUser.map((item) => {
            return {
                value: item.id,
                label: `${item.fullName} - (${item.username})`,
            };
        });
        const userSelectList = userItem.map((item) => {
            return {
                value: item.id,
                label: `${item.fullName} - (${item.username})`,
            };
        });
        // const result = [...userSelect, ...userSelectList];
        const result = unionWith(
            userSelect,
            userSelectList,
            (a, b) => a.value === b.value
        );

        return result;
    }
    return userItem.map((item) => {
        return {
            value: item.id,
            label: `${item.fullName} - (${item.username})`,
        };
    });
};

const TaskUpdate = (props: ITaskUpdateProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { initialState } = useSelector(
        (state: RootState) => state.tasksReducer
    );
    const { createEntitySuccess } = initialState;
    const { initialState: projectInitialState } = useSelector(
        (state: RootState) => state.projectReducer
    );
    const { projectStaff } = projectInitialState;
    const { visible, setVisible, taskObj, project, taskType, detailTask } =
        props;
    const [taskFolder, setTaskFolder] = useState<ITask[]>(
        project.tasks
            .flatMap((task) => [task, ...(task.children || [])])
            .filter((task) => task.type === TaskTypeEnum.FOLDER)
    );
    const [preTask, setPreTask] = useState<ITask>();

    const modalLabel =
        taskType === TaskTypeEnum.FOLDER ? "Thư mục" : "Công việc";

    const handleOnClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (detailTask && detailTask !== taskIdNameDefault.TOPPROJECT) {
            (async () => {
                const response = await axios.get(`task/${detailTask}`);
                setPreTask(response?.data?.data);
            })();
        }
    }, [detailTask]);

    useEffect(() => {
        setTaskFolder(
            project.tasks
                .flatMap((task) => [task, ...(task.children || [])])
                .filter((task) => task.type === TaskTypeEnum.FOLDER)
        )
    }, [JSON.stringify(project)])

    useEffect(() => {
        if (createEntitySuccess) {
            setVisible(false);
            ToastSuccess(
                taskObj
                    ? `Cập nhật ${modalLabel} thành công`
                    : `Tạo ${modalLabel} thành công`
            );
            dispatch(resetEntity());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createEntitySuccess]);

    const initialValues: INewTask = {
        name: "",
        description: "",
        startedDate: "", // '2023-09-18T17:10:08.069Z';
        endDate: "", //'2023-09-18T17:10:08.069Z';
        estimate: 1,
        // assignId: 1,
        assignIds: [],
        // leaderId: 0,
        priority: 0,
        category: TaskCategoryEnum.ANIMATION,
        type: taskType,
        status: TaskStatusEnum.NEWTASK,
        projectId: project.projectId,
        parentId: null,
        files: "",
        filesArr: [],
    };

    const initialFormState = taskObj
        ? { ...taskObj, filesArr: handleJsonParseFileArr(taskObj.files) }
        : initialValues;

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required("Không được để trống"),
        // description: Yup.string().trim().required('Không được để trống'),
        startedDate: Yup.string()
            .trim()
            .nullable()
            .required("Vui lòng chọn ngày bắt đầu")
            .test(
                "startDay-than-endDay",
                "Ngày bắt đầu dự án không được lớn hơn ngày kết thúc",
                function (value) {
                    return checkIsDateBefore(
                        dayjs(value),
                        dayjs(this.parent.endDate)
                    );
                }
            ),
        endDate: Yup.string()
            .trim()
            .required("Vui lòng chọn ngày kết thúc")
            .test(
                "endDay-not-than-startDay",
                "Ngày kết thúc dự án không được nhỏ hơn ngày bắt đầu",
                function (value) {
                    return checkIsDateAfter(
                        dayjs(value),
                        dayjs(this.parent.startedDate)
                    );
                }
            ),
        estimate: Yup.string()
            .matches(/^[0-9]+$/, "Vui lòng nhập số")
            .test("is-number", "Giá trị phải là số hợp lệ", (value) => {
                if (!value) return true; // Cho phép giá trị rỗng (sẽ được xử lý bởi .required())
                const number = Number(value);
                return !isNaN(number) && number > 0 && Number.isInteger(number);
            })
            .required("Không được để trống"),
        // assignId: Yup.number().required('Không được để trống').min(1, 'Không được để trống'),
        // leaderId: Yup.number().required('Không được để trống').min(1, 'Không được để trống'),
        // priority: Yup.number().required('Không được để trống').min(1, 'Không được để trống'),

        status: Yup.string().trim().required("Không được để trống"),
        category: Yup.string().trim().required("Không được để trống"),
    });

    const validationFolderSchema = Yup.object().shape({
        name: Yup.string().trim().required("Không được để trống"),
        // description: Yup.string().trim().required('Không được để trống'),
        startedDate: Yup.string()
            .trim()
            .required("Vui lòng chọn ngày bắt đầu")
            // .test('startDay-current', 'Ngày bắt đầu dự án không được là ngày quá khứ', function (value) {
            //   return checkIsDateBefore(dayjs(), dayjs(value));
            // })
            .test(
                "startDay-than-endDay",
                "Ngày bắt đầu dự án không được lớn hơn ngày kết thúc",
                function (value) {
                    return checkIsDateBefore(
                        dayjs(value),
                        dayjs(this.parent.endDate)
                    );
                }
            ),
        endDate: Yup.string()
            .trim()
            .required("Vui lòng chọn ngày kết thúc")
            // .test('endDay-current', 'Ngày kết thúc dự án không được là ngày quá khứ', function (value) {
            //   return checkIsDateBefore(dayjs(), dayjs(value));
            // })
            .test(
                "endDay-not-than-startDay",
                "Ngày kết thúc dự án không được nhỏ hơn ngày bắt đầu",
                function (value) {
                    return checkIsDateAfter(
                        dayjs(value),
                        dayjs(this.parent.startedDate)
                    );
                }
            ),
        // assignId: Yup.number().required('Không được để trống').min(1, 'Không được để trống'),
        // leaderId: Yup.number().required('Không được để trống').min(1, 'Không được để trống'),
        // priority: Yup.number().required('Không được để trống').min(1, 'Không được để trống'),
        estimate: Yup.string()
            .matches(/^[0-9]+$/, "Vui lòng nhập số")
            .test("is-number", "Giá trị phải là số hợp lệ", (value) => {
                if (!value) return true; // Cho phép giá trị rỗng (sẽ được xử lý bởi .required())
                const number = Number(value);
                return !isNaN(number) && number > 0 && Number.isInteger(number);
            })
            .required("Không được để trống"),
        status: Yup.string().trim().required("Không được để trống"),
    });

    /**
     * Handle User multiple select
     */

    const [userFilter, setUserFilter] = useState<IUserParams>({
        page: 1,
        limit: 20,
        status: ResponseStatus.ACTIVE,
    });

    useEffect(() => {
        dispatch(
            getProjectStaff({ id: project.projectId, field: { ...userFilter } })
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(userFilter)]);

    const customUserItems: ISelectValue<string>[] = returnUserMultipleItem(
        projectStaff.map((item) => item.user),
        taskObj?.assigns ? taskObj?.assigns?.map((item) => item.assign) : []
    );

    const returnMutiOption = (
        optionValue: number[],
        array: ISelectValue<string>[]
    ): ISelectValue<string>[] => {
        if (!optionValue) return [];
        const selectValue = array?.filter((item) =>
            optionValue.includes(Number(item.value))
        );
        return selectValue;
    };

    /**
     * Handle Folder select
     */

    const customFolderItems: ISelectValue<string>[] = taskFolder
        ? taskFolder.map((item) => {
              return {
                  ...item,
                  value: item.taskId.toString(),
                  label: item.name,
              };
          })
        : [];

    const returnOption = (
        optionValue: number | null | undefined,
        array: ISelectValue<string>[]
    ) => {
        if (!optionValue) return null;
        if (!array.length) return null;
        return array.find(({ value }) => Number(value) === optionValue);
    };

    const getRangeLimitDate = (
        limit: "max" | "min",
        parentId?: number | null
    ) => {
        if (!parentId) return undefined;
        const parentTask = taskFolder.find((item) => item.taskId === parentId);
        if (!parentTask) return undefined;
        if (limit === "max") {
            return parentTask.endDate
                ? new Date(parentTask.endDate)
                : undefined;
        } else {
            return parentTask.startedDate
                ? new Date(parentTask.startedDate)
                : undefined;
        }
    };

    const handleRemapping = async (value: ITask) => {
        const filesPromies = await handleUploadMultipleFile([
            ...(value.filesArr || []),
            ...(taskObj?.filesArr || []),
        ]);
        value.files = filesPromies;
        value.filesArr = [];
        return value;
    };
    return (
        <SModal
            visible={visible}
            onClose={handleOnClose}
            backdrop="static"
            className="custom-modal"
        >
            <Formik
                initialValues={initialFormState}
                validationSchema={
                    taskType === TaskTypeEnum.FOLDER
                        ? validationFolderSchema
                        : validationSchema
                }
                // validateOnChange={false}
                validateOnBlur={false}
                onSubmit={async (value) => {
                    value.estimate = Number(value.estimate);
                    const values = await handleRemapping(value as ITask);
                    if (values.status === ("NEWTASK" as TaskStatusEnum)) {
                        values.status = "NEW" as TaskStatusEnum;
                    }
                    const body = { ...values, projectType: "ALL_PROJECT" };
                    dispatch(fetching());
                    if (taskObj && !detailTask) {
                        dispatch(updateEntity(body as ITask));
                    } else if (detailTask && detailTask !== taskIdNameDefault.TOPPROJECT) {
                        const bodyCreate = {
                            ...values,
                            parentId: preTask?.taskId,
                            projectType: "ALL_PROJECT",
                        };
                        dispatch(createEntity(bodyCreate));
                    } else {
                        dispatch(createEntity(body));
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue,
                }) => (
                    <CForm onSubmit={handleSubmit} className="custom-form">
                        <>
                            <CModalHeader>
                                {taskObj
                                    ? `Chỉnh sửa ${modalLabel}`
                                    : `Tạo ${modalLabel}`}
                            </CModalHeader>
                            <CModalBody>
                                <CRow className="gap-20">
                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">
                                            Tên{" "}
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </CFormLabel>

                                        <CFormInput
                                            className="form-height-44"
                                            placeholder="Nhập tên"
                                            name="name"
                                            autoComplete="off"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={
                                                !!errors.name && touched.name
                                            }
                                        />
                                        <CFormFeedback
                                            invalid
                                            className={
                                                !!errors.name && touched.name
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                        >
                                            {errors.name}
                                        </CFormFeedback>
                                    </CCol>
                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">
                                            Mô tả
                                        </CFormLabel>
                                        <CustomFormikEditor
                                            simpleMode
                                            size="sm"
                                            name="description"
                                            value={values.description}
                                            setFieldValue={setFieldValue}
                                        />
                                        <CFormFeedback
                                            invalid
                                            className={
                                                !!errors.description &&
                                                touched.description
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                        >
                                            {errors.description}
                                        </CFormFeedback>
                                    </CCol>

                                    {!preTask && (
                                        <CCol
                                            xs="12"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <CFormLabel className="mb-6 label-gray-700">
                                                Link tới
                                            </CFormLabel>
                                            <Select
                                                defaultValue={null}
                                                className="custom-select height-44"
                                                classNamePrefix="react-select"
                                                value={returnOption(
                                                    values.parentId,
                                                    customFolderItems
                                                )}
                                                onChange={async (
                                                    newValue: SingleValue<ISelectValue<string> | null>
                                                ) => {
                                                    setFieldValue(
                                                        `parentId`,
                                                        Number(
                                                            newValue?.value
                                                        ) || 0
                                                    );
                                                    setFieldValue("startedDate", "");
                                                    setFieldValue("endDate", undefined);
                                                }}
                                                id={"parentId"}
                                                noOptionsMessage={() => (
                                                    <>Không có thư mục nào</>
                                                )}
                                                // onInputChange={textValue.onInputChange}
                                                // styles={customSelectStyles}
                                                options={customFolderItems}
                                                placeholder={"Chọn thư mục..."}
                                                isClearable={true}
                                                closeMenuOnSelect={true}
                                                menuPosition={"absolute"}
                                                //   menuPortalTarget={document.body}
                                            />
                                            <CFormFeedback
                                                invalid
                                                className={
                                                    !!errors.parentId &&
                                                    touched.parentId
                                                        ? "d-block"
                                                        : "d-none"
                                                }
                                            >
                                                {errors.parentId}
                                            </CFormFeedback>
                                        </CCol>
                                    )}
                                    <CCol xs="12">
                                        <CRow>
                                            <CCol xs={12} md={6}>
                                                <CFormLabel className="mb-6 label-gray-700">
                                                    Ngày bắt đầu{" "}
                                                    <span
                                                        style={{ color: "red" }}
                                                    >
                                                        *
                                                    </span>
                                                </CFormLabel>
                                                <CDatePicker
                                                    className="form-height-44"
                                                    locale="vi-VN"
                                                    weekdayFormat={"narrow"}
                                                    id="startedDate"
                                                    date={
                                                        values.startedDate
                                                            ? new Date(
                                                                  values.startedDate
                                                              )
                                                            : undefined
                                                    }
                                                    onDateChange={(date) => {
                                                        if (date) {
                                                            const startDate =
                                                                dayjs(
                                                                    date
                                                                ).startOf(
                                                                    "day"
                                                                );
                                                            // Kiểm tra xem ngày bắt đầu có nằm trong khoảng thời gian của dự án không
                                                            if (
                                                                startDate.isBefore(
                                                                    dayjs(
                                                                        project.startedDate
                                                                    )
                                                                ) ||
                                                                startDate.isAfter(
                                                                    dayjs(
                                                                        project.endedDate
                                                                    )
                                                                )
                                                            ) {
                                                                return; // Không cập nhật nếu ngoài khoảng
                                                            }
                                                            setFieldValue(
                                                                "startedDate",
                                                                startDate.toISOString()
                                                            );

                                                            // Nếu ngày bắt đầu lớn hơn ngày kết thúc, cập nhật ngày kết thúc
                                                            if (
                                                                values.endDate &&
                                                                startDate.isAfter(
                                                                    dayjs(
                                                                        values.endDate
                                                                    )
                                                                )
                                                            ) {
                                                                setFieldValue(
                                                                    "endDate",
                                                                    startDate
                                                                        .add(
                                                                            1,
                                                                            "day"
                                                                        )
                                                                        .toISOString()
                                                                );
                                                            }
                                                        } else {
                                                            setFieldValue(
                                                                "startedDate",
                                                                ""
                                                            );
                                                        }
                                                    }}
                                                    placeholder="Chọn ngày bắt đầu"
                                                    onBlur={handleBlur}
                                                    minDate={
                                                        values.parentId && Number(values.parentId) ?  new Date(
                                                            //@ts-ignore
                                                            taskFolder?.find((value) => Number(value.taskId) === Number(values.parentId))?.startedDate) : 
                                                        (Number(detailTask) && Number(detailTask) !== project.projectId ?  new Date(
                                                            //@ts-ignore
                                                            taskFolder?.find((value) => Number(value.taskId) === Number(detailTask))?.startedDate
                                                        ) : new Date(
                                                            project.startedDate
                                                        ))
                                                    }
                                                    maxDate={
                                                        values.parentId && Number(values.parentId) ?  new Date(
                                                            //@ts-ignore
                                                            taskFolder?.find((value) => Number(value.taskId) === Number(values.parentId))?.endDate) :
                                                        Number(detailTask) && Number(detailTask) !== project.projectId ? new Date(
                                                            //@ts-ignore
                                                            taskFolder?.find((value) => Number(value.taskId) === Number(detailTask))?.endDate
                                                        ) : new Date(
                                                            project.endedDate
                                                        ) 
                                                    }
                                                    invalid={
                                                        !!errors.startedDate &&
                                                        touched.startedDate
                                                    }
                                                />
                                                <CFormFeedback
                                                    invalid
                                                    className={
                                                        !!errors.startedDate &&
                                                        touched.startedDate
                                                            ? "d-block"
                                                            : "d-none"
                                                    }
                                                >
                                                    {errors.startedDate}
                                                </CFormFeedback>
                                            </CCol>

                                            <CCol xs={12} md={6}>
                                                <CFormLabel className="mb-6 label-gray-700">
                                                    Ngày kết thúc{" "}
                                                    <span
                                                        style={{ color: "red" }}
                                                    >
                                                        *
                                                    </span>
                                                </CFormLabel>
                                                <CDatePicker
                                                    id="endDate"
                                                    disabled={
                                                        !values.startedDate
                                                    }
                                                    className="form-height-44"
                                                    locale="vi-VN"
                                                    weekdayFormat={"narrow"}
                                                    placeholder="Chọn ngày kết thúc"
                                                    date={
                                                        values.endDate
                                                            ? dayjs(
                                                                  values.endDate
                                                              ).toDate()
                                                            : null
                                                    }
                                                    onDateChange={(date) => {
                                                        date
                                                            ? setFieldValue(
                                                                  "endDate",
                                                                  date
                                                                      ? dayjs(
                                                                            date
                                                                        )
                                                                            .endOf(
                                                                                "days"
                                                                            )
                                                                            .toISOString()
                                                                      : null
                                                              )
                                                            : setFieldValue(
                                                                  "endDate",
                                                                  ""
                                                              );
                                                    }}
                                                    onBlur={handleBlur}
                                                    minDate={
                                                        values.startedDate
                                                        ? new Date(
                                                                values.startedDate
                                                            )
                                                        : (Number(detailTask) && Number(detailTask) !== project.projectId ? new Date(
                                                            //@ts-ignore
                                                            taskFolder?.find((value) => Number(value.taskId) === Number(detailTask))?.startedDate
                                                        ) : new Date(
                                                            project.startedDate
                                                        ))
                                                    }
                                                    maxDate={
                                                        values.parentId && Number(values.parentId) ?  new Date(
                                                            //@ts-ignore
                                                            taskFolder?.find((value) => Number(value.taskId) === Number(values.parentId))?.endDate) :
                                                        Number(detailTask) && Number(detailTask) !== project.projectId ? new Date(
                                                            //@ts-ignore
                                                            taskFolder?.find((value) => Number(value.taskId) === Number(detailTask))?.endDate
                                                        ) : new Date(
                                                            project.endedDate
                                                        ) 
                                                    }
                                                    invalid={
                                                        !!errors.endDate &&
                                                        touched.endDate
                                                    }
                                                />
                                                <CFormFeedback
                                                    invalid
                                                    className={
                                                        !!errors.endDate &&
                                                        touched.endDate
                                                            ? "d-block"
                                                            : "d-none"
                                                    }
                                                >
                                                    {errors.endDate}
                                                </CFormFeedback>
                                            </CCol>
                                        </CRow>
                                    </CCol>

                                    <CCol
                                        xs="12"
                                        // className={`${
                                        //   taskType === TaskTypeEnum.FOLDER ? "d-none" : ""
                                        // }`}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <CFormLabel className="mb-6 label-gray-700">
                                            Giao cho
                                        </CFormLabel>
                                        <Select
                                            defaultValue={null}
                                            className="custom-select multi-select height-44"
                                            classNamePrefix="react-select"
                                            value={returnMutiOption(
                                                values.assignIds || [],
                                                customUserItems
                                            )}
                                            onChange={async (
                                                newValue: MultiValue<ISelectValue<string> | null>
                                            ) => {
                                                if (newValue.length === 0) {
                                                    setUserFilter({
                                                        page: 1,
                                                        limit: 20,
                                                        status: ResponseStatus.ACTIVE,
                                                    });
                                                }
                                                const optionValue =
                                                    newValue.map((item) =>
                                                        Number(item?.value)
                                                    );
                                                setFieldValue(
                                                    `assignIds`,
                                                    optionValue
                                                );
                                            }}
                                            onMenuScrollToBottom={() => {
                                                setUserFilter({
                                                    ...userFilter,
                                                    limit:
                                                        userFilter.limit + 20,
                                                });
                                            }}
                                            onInputChange={(
                                                newValue: string
                                            ) => {
                                                setUserFilter({
                                                    ...userFilter,
                                                    key: newValue,
                                                });
                                            }}
                                            id={"assignIds"}
                                            noOptionsMessage={() => (
                                                <>Không có thành viên nào</>
                                            )}
                                            // onInputChange={textValue.onInputChange}
                                            // styles={customSelectStyles}
                                            options={customUserItems}
                                            placeholder={"Chọn thành viên..."}
                                            isClearable={true}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            menuPosition={"absolute"}
                                            //   menuPortalTarget={document.body}
                                        />
                                        <CFormFeedback
                                            invalid
                                            className={
                                                !!errors.assignIds &&
                                                touched.assignIds
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                        >
                                            {errors.assignIds}
                                        </CFormFeedback>
                                    </CCol>

                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">
                                            Ước tính công việc{" "}
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </CFormLabel>

                                        <CInputGroup className="input-end-group">
                                            <CFormInput
                                                className="form-height-44"
                                                name="estimate"
                                                autoComplete="off"
                                                value={values.estimate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                invalid={
                                                    !!errors.estimate &&
                                                    touched.estimate
                                                }
                                            />{" "}
                                            <CInputGroupText
                                                id="search-addon"
                                                className=" form-height-44"
                                            >
                                                <span className="text-sm text-gray-modern-900">
                                                    Ngày
                                                </span>
                                            </CInputGroupText>
                                        </CInputGroup>
                                        <CFormFeedback
                                            invalid
                                            className={
                                                !!errors.estimate &&
                                                touched.estimate
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                        >
                                            {errors.estimate}
                                        </CFormFeedback>
                                    </CCol>

                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">
                                            Trạng thái
                                        </CFormLabel>
                                        <CFormSelect
                                            className="form-height-44"
                                            name="status"
                                            value={values.status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={
                                                !!errors.status &&
                                                touched.status
                                            }
                                        >
                                            {taskStatusArray.map(
                                                (item, index) => (
                                                    <option
                                                        key={index}
                                                        value={item}
                                                    >
                                                        {
                                                            mapTaskStatusToString[
                                                                item
                                                            ]
                                                        }
                                                    </option>
                                                )
                                            )}
                                        </CFormSelect>
                                        <CFormFeedback
                                            invalid
                                            className={
                                                !!errors.status &&
                                                touched.status
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                        >
                                            {errors.status}
                                        </CFormFeedback>
                                    </CCol>

                                    <CCol
                                        xs="12"
                                        className={`${
                                            taskType === TaskTypeEnum.FOLDER
                                                ? "d-none"
                                                : ""
                                        }`}
                                    >
                                        <CFormLabel className="mb-6 label-gray-700">
                                            Nhãn
                                        </CFormLabel>
                                        <CFormSelect
                                            className="form-height-44"
                                            name="category"
                                            value={values.category}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={
                                                !!errors.category &&
                                                touched.category
                                            }
                                        >
                                            {taskCategoryArray.map(
                                                (item, index) => (
                                                    <option
                                                        key={index}
                                                        value={item}
                                                    >
                                                        {
                                                            mapTaskCategoryToString[
                                                                item
                                                            ]
                                                        }
                                                    </option>
                                                )
                                            )}
                                        </CFormSelect>
                                        <CFormFeedback
                                            invalid
                                            className={
                                                !!errors.category &&
                                                touched.category
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                        >
                                            {errors.category}
                                        </CFormFeedback>
                                    </CCol>

                                    <CCol xs="12">
                                        <CFormLabel className="mb-6 label-gray-700">
                                            Tài liệu tải lên
                                        </CFormLabel>
                                        <FormikMultiFileUpload
                                            name="filesArr"
                                            setFieldValue={setFieldValue}
                                            fileValue={values.filesArr || []}
                                        />
                                    </CCol>
                                </CRow>
                            </CModalBody>
                            <CModalFooter className="d-flex justify-content-end">
                                <CButton
                                    className="btn-custom minw-120 variant-gray-300"
                                    type="button"
                                    onClick={() => {
                                        resetForm();
                                        setVisible(false);
                                    }}
                                >
                                    Huỷ
                                </CButton>
                                <CButton
                                    className="btn-custom minw-120 primary-500"
                                    type="submit"
                                >
                                    Lưu
                                </CButton>
                            </CModalFooter>
                        </>
                    </CForm>
                )}
            </Formik>
        </SModal>
    );
};

export default TaskUpdate;
