import { IParams } from "@/shared/shared-interfaces";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../../shared/config/axios-interceptor";
import { pickBy } from "lodash";
import {
    IDictionaryParentManagement,
    INewDictionaryParentManagement,
} from "@/shared/model/dictionaryManagement.modal";

const prefix = "dict";

export interface IDictionaryParentParams extends IParams {}

export const getEntities = createAsyncThunk(
    `get-all-dictionary-parent-${prefix}`,
    async (fields: IDictionaryParentParams, thunkAPI) => {
        try {
            const params = pickBy(fields);
            return await axios.get<IDictionaryParentManagement[]>(`${prefix}`, {
                params,
            });
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// export const getEntity = createAsyncThunk(
//     `get-single-dictionary-parent-${prefix}`,
//     async (id: string, thunkAPI) => {
//         try {
//             const { data } = await axios.get<IdictionaryParentManagement>(`${prefix}/${id}`);
//             return data;
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     }
// );

export const createEntity = createAsyncThunk(
    `create-one-dictionary-parent-${prefix}`,
    async (body: INewDictionaryParentManagement, thunkAPI) => {
        try {
            const { data } = await axios.post(`${prefix}`, pickBy(body));
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateEntity = createAsyncThunk(
    `update-one-dictionary-parent-${prefix}`,
    async (body: IDictionaryParentManagement, thunkAPI) => {
        try {
            const { dictId } = body;
            const { data } = await axios.put<IDictionaryParentManagement>(
                `${prefix}`,
                pickBy(body)
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const removeEntity = createAsyncThunk(
    `delete-one-dictionary-parent-${prefix}`,
    async (id: string, thunkAPI) => {
        try {
            return await axios.delete(`${prefix}/${id}`);
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// export const getAllEntities = createAsyncThunk(
//     `get-all-entities-${prefix}`,
//     async (_, thunkAPI) => {
//         try {
//             return await axios.get<IUser[]>(`${prefix}`);
//         } catch (error: any) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     }
// );
