import taskResultReducer from "@/app/modules/Project/Detail/Tasks/TaskDetailContainer/TaskResult/tasksResult.reducer";
import { combineReducers } from "redux";
import container from "./app/containers/reducer";
import messageReducer from "./app/modules/Chat/Message/message.reducer";
import chatReducer from "./app/modules/Chat/chat.reducer";
import postsReducer from "./app/modules/Project/Detail/Forums/Post/posts.reducer";
import projectMemberReducer from "./app/modules/Project/Detail/Members/memberManagement.reducer";
import tasksReducer from "./app/modules/Project/Detail/Tasks/tasksManagement.reducer";
import projectReducer from "./app/modules/Project/projectManagement.reducer";
import permissionReducer from "./app/modules/SystemSetting/PermissionGroup/permission.reducer";
import usersReducer from "./app/modules/SystemSetting/UserManagement/usersManagement.reducer";
import authentication from "./app/modules/auth/auth.reducer";
import myTask from "./app/modules/MyTask/store/myTask.reducer";
import departmentReducer from "./app/modules/department/components/department.reducer";
import dictionaryParentReducer from "./app/modules/SystemSetting/DictionaryManagement/parentManegement/dictionaryManagement.reducer";
import dictionaryChildrenReducer from "./app/modules/SystemSetting/DictionaryManagement/childrenManagement/dictionaryManagement.reducer";
import playReducer from "./app/shared/TheVideoLayout/store/playlist.reducer";
import videoReducer from "./app/shared/TheVideoLayout/VideoControl/store/video.reducer";
import commentReducer from "./app/shared/TheVideoLayout/comment/comment.reducer";
import layoutReducer from "./app/shared/TheVideoLayout/layer/layer.reducer";
import calenderReducer from "./app/modules/Calender/store/calender.reducer";
import roleMenuReducer from "./app/modules/SystemSetting/MenuRole/role.reducer";

const rootReducer = combineReducers({
  container,
  authentication,
  usersReducer,
  permissionReducer,
  projectReducer,
  messageReducer,
  tasksReducer,
  chatReducer,
  projectMemberReducer,
  postsReducer,
  taskResultReducer,
  myTask,
  departmentReducer,
  dictionaryParentReducer,
  dictionaryChildrenReducer,
  playReducer,
  videoReducer,
  commentReducer,
  layoutReducer,
  calenderReducer,
  roleMenuReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
