import { ReactComponent as ClipboardIcon } from "@/assets/img/clipboard-icon.svg";
import { ReactComponent as FolderIcon } from "@/assets/img/folder-icon.svg";
import { ReactComponent as HomeIcon } from "@/assets/img/home-icon.svg";

import AvatarContainer from "@/app/shared/AvatarContainer/AvatarContainer";
import StatusSelect from "@/app/shared/StatusSelect/StatusSelect";
// import { ITaskTree } from '@/components/shared/TableTree/TableTreeCustom';
import TaskProgress, {
    IProgressBar,
} from "@/app/shared/TaskProgress/TaskProgress";
import PlusIcon from "@/app/shared/icons/PlusIcon";
import { RootState } from "@/reducers";
import {
    SubTaskStatusEnum,
    TaskStatusEnum,
    TaskTypeEnum,
    mapTaskStatusToColor,
    subMapTaskStatusToColor,
    subMapTaskStatusToString,
    subTaskStatusArray,
    taskIdNameDefault,
} from "@/shared/enumeration/task.enum";
import { IProject } from "@/shared/model/project.modal";
import { ISubTaskProgress, ITask } from "@/shared/model/task.model";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { AppDispatch } from "@/store";
import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CFormSwitch,
} from "@coreui/react-pro";
import { setCulture } from "@syncfusion/ej2-base";
import {
    ColumnDirective,
    ColumnsDirective,
    EventMarkerDirective,
    EventMarkersDirective,
    GanttComponent,
    SplitterView,
    TaskFieldsModel,
    TimelineSettingsModel,
} from "@syncfusion/ej2-react-gantt";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskDetailModal from "./TaskDetailContainer/TaskDetailModal";
import TaskUpdate from "./TaskUpdate";
import { getEntities, updateEntity } from "./tasksManagement.api";
import { getEntities as getMembers } from "../Members/memberManagement.api";
import {
    fetching,
    resetEntity,
    setFilterState,
} from "./tasksManagement.reducer";
import CustomDropDownButton from "@/app/shared/CustomDropDownButton/DropDownButton";
import iconFiltter from "../../../../../assets/img/Iconfiltter.png";
import { getEntity } from "../../projectManagement.api";
import { useTaskResults } from "./TaskDetailContainer/TaskResult/useTaskResult";
import { projectMembersSelectors } from "../Members/memberManagement.reducer";
import { sumArray } from "@/shared/utils/ultils";
import { ToastSuccess } from "@/app/shared/toast/Toast";

setCulture("vi");
dayjs.extend(weekOfYear);

export const taskStatusProgressArray: TaskStatusEnum[] = [
    TaskStatusEnum.NEWTASK,
    TaskStatusEnum.PROCESSING,
    TaskStatusEnum.REVIEW,
    TaskStatusEnum.COMPLETED,
];

export const statusProgressArray: SubTaskStatusEnum[] = [
    SubTaskStatusEnum.NEW,
    SubTaskStatusEnum.PROCESSING,
    SubTaskStatusEnum.REVIEW,
    SubTaskStatusEnum.COMPLETED,
];

export interface ITaskTree extends ITask {
    treeStartedDate: Date;
    treeEndDate: Date;
    children: ITaskTree[];
    diffDate?: number;
}

// const flattenTaskTree = (tree: ITask[], statusArr: TaskStatusEnum[]): ITask[] => {
//   const result: ITask[] = [];
//   tree.forEach((item) => {
//     if (item.type === TaskTypeEnum.TASK && (statusArr.includes(item.status) || statusArr.length === 0)) {
//       result.push(item);
//     }
//     if (item.children) {
//       result.push(...flattenTaskTree(item.children, statusArr));
//     }
//   });
//   return result;
// };

// const calculatePercentage = (taskListTotal: ITaskTree[], status: TaskStatusEnum) => {
//   const totalTask = taskListTotal.length;
//   const totalTaskDone = taskListTotal.filter((item) => item.status === status).length;
//   const percentage = (totalTaskDone / totalTask) * 100;
//   return percentage;
// };

export const handleGetProgressBar = (
    treeItem: ITaskTree,
    statusArr: TaskStatusEnum[]
) => {
    const process = treeItem?.process;
    const taskProgress: IProgressBar[] = statusArr?.map((item) => ({
        className: mapTaskStatusToColor[item],
        progress: process
            ? //@ts-ignore
              process?.[item.toLowerCase() as keyof ISubTaskProgress] || 0
            : 0,
    }));
    const total = sumArray(taskProgress.map((value) => value.progress));
    return taskProgress.map(({ className, progress }) => ({
        className,
        progress: (progress / total) * 100,
    }));
};

export const handleSubGetProgressBar = (
    treeItem: ITaskTree,
    statusArr: SubTaskStatusEnum[]
) => {
    const process = treeItem?.process;
    const taskProgress: IProgressBar[] = statusArr?.map((item) => {
        if (item === "NEW") {
            return {
                className: subMapTaskStatusToColor[item],
                progress: process
                    ? //@ts-ignore
                      process?.newed || process?.newtask
                    : 0,
            };
        }
        return {
            className: subMapTaskStatusToColor[item],
            progress: process
                ? //@ts-ignore
                  process?.[item.toLowerCase() as keyof ISubTaskProgress] || 0
                : 0,
        };
    });
    const total = sumArray(taskProgress.map((value) => value.progress));
    return taskProgress.map(({ className, progress }) => ({
        className,
        progress: (progress / total) * 100,
    }));
};

const getTaskSubItem = (parentId: number, taskList: ITask[]): ITaskTree[] => {
    const childrens: ITaskTree[] = [];
    taskList?.forEach((item) => {
        if (item?.parentId === parentId) {
            const taskSubTree =
                item?.taskId && taskList
                    ? getTaskSubItem(item?.taskId, taskList)
                    : [];
            const date = item.status === "NEW" as TaskStatusEnum && item.actualProgress === 0 ? 0 :
                    Number(
                        calculateDiffDay(
                            item?.startedDate || "",
                            item?.endDate || ""
                        )
                    //@ts-ignore
                    ) - item?.actualProgress;
            
            const childObj: ITaskTree = {
                ...item,
                id: item?.taskId,
                children: taskSubTree,
                treeStartedDate: dayjs(item?.startedDate).toDate(),
                treeEndDate: dayjs(item?.endDate).toDate(),
                diffDate: date
            };
            childrens.push(childObj);
        }
    });
    return childrens;
};

const calculateDiffDay = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return 0;
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const diff = end.diff(start, "day");
    return diff.toFixed(2);
};

const taskTreeList = (taskLists: ITask[]) => {
    const treeItems: ITaskTree[] = [];
    taskLists?.forEach((item) => {
        if (item?.parentId === null) {
            const taskSubTree = getTaskSubItem(item?.taskId, taskLists);
            const date = item.status === "NEW" as TaskStatusEnum && item.actualProgress === 0 ? 0 :
                    Number(
                        calculateDiffDay(
                            item?.startedDate || "",
                            item?.endDate || ""
                        )
                    //@ts-ignore
                    ) - item?.actualProgress;
            treeItems.push({
                ...item,
                id: item?.taskId,
                children: taskSubTree,
                treeStartedDate: dayjs(item?.startedDate).toDate(),
                treeEndDate: dayjs(item?.endDate).toDate(),
                diffDate: date
            });
        }
    });

    return treeItems;
};

const convertTaskArrayToProcess = (taskList: ITask[]) => {
    const totalTask = taskList?.filter(
        (item) =>
            item.type === TaskTypeEnum.TASK &&
            taskStatusProgressArray?.includes(item.status)
    ).length;
    const process = {
        processing: 0,
        review: 0,
        completed: 0,
        newed: 0,
    };
    taskList?.forEach((item) => {
        if (item.status === TaskStatusEnum.NEWTASK) {
            process.newed += 1;
        }
        if (item.status === ("NEW" as TaskStatusEnum)) {
            process.newed += 1;
        }
        if (item.status === TaskStatusEnum.PROCESSING) {
            process.processing += 1;
        }
        if (item.status === TaskStatusEnum.REVIEW) {
            process.review += 1;
        }
        if (item.status === TaskStatusEnum.COMPLETED) {
            process.completed += 1;
        }
    });
    const processPercent = {
        processing: (process?.processing / totalTask) * 100,
        newed: (process?.newed / totalTask) * 100,
        review: (process?.review / totalTask) * 100,
        completed: (process?.completed / totalTask) * 100,
    };
    return processPercent;
};

export const renderTaskTree = (tasks: ITask[], projectDetail?: IProject) => {
    const tasksTreeList: ITaskTree[] =
        tasks.length > 0 ? taskTreeList(tasks) : [];
    const process = convertTaskArrayToProcess(tasks ?? "");
    // do recursive logic here

    // const taskReport: ITaskTree = {
    //   id: 'total',
    // };
    const taskTree: any[] = [
        {
            id: taskIdNameDefault.TOPPROJECT,
            taskId: 0,
            name: projectDetail?.name || "",
            process: process,
            treeStartedDate: dayjs(
                projectDetail?.startedDate || undefined
            )?.toDate(),
            treeEndDate: dayjs(projectDetail?.endedDate || undefined)?.toDate(),
            children: [...tasksTreeList],
            projectId: projectDetail?.projectId,
            duration: dayjs(projectDetail?.endedDate || undefined).diff(dayjs(
                projectDetail?.startedDate || undefined
            ), 'day'),
            predecessor: ""
        },
    ];
    const totalTaskTree: ITaskTree[] = [...taskTree];
    return totalTaskTree;
};

const TasksManagementGantt = () => {
    const { params } = useRouter();
    const { id } = params;
    const dispatch = useDispatch<AppDispatch>();
    const { initialState } = useSelector(
        (state: RootState) => state.tasksReducer
    );
    const [tasks, setTasks] = useState<ITask[]>([]);

    const [isLoading, setIsLoading] = useState(true);
    // const projectDetail = useSelector(selectProjectById(id || ""));
    const [projectDetail, setProjectDetail] = useState<IProject>();
    const currentDate = new Date();
    const { filterState, updateEntitySuccess, createEntitySuccess } = initialState;

    const [viewSetting, setViewSetting] = useState<SplitterView>("Grid");

    const [createTask, setCreateTask] = useState<boolean>(false);
    const [updateTask, setUpdateTask] = useState<ITask | null>(null);
    const [detailTask, setDetailTask] = useState<number | string | null>(null);
    const prop = useSelector((state: RootState) => state.tasksReducer);
    const state = prop.initialState;
    const { user } = useSelector((state: RootState) => state.authentication);
    const projectMembers = useSelector(projectMembersSelectors.selectAll);
    const stateMember = useSelector(
        (state: RootState) => state.projectMemberReducer.initialState
    );

    const handleCancelsetDetailTask = (update?: boolean) => {
        setDetailTask(null);
    };

    const handleCreateTask = () => {
        setCreateTask(true);
    };

    const handleCancelUpdateTask = (update?: boolean) => {
        setUpdateTask(null);
    };

    const [createFolder, setCreateFolder] = useState<boolean>(false);
    const [updateFolder, setUpdateFolder] = useState<ITask | null>(null);
    const [taskId, setTaskId] = useState<number>();
    const { fetchAndCheckResults } = useTaskResults();

    const [activeSortMode, setSortMode] = useState({
        name: "Sắp xếp theo",
        value: "",
    });

    const handleCreateFolder = () => {
        setCreateFolder(true);
    };

    const onSelectTasId = (taskId: number) => {
        setTaskId(taskId);
    };
    const handleCancelUpdateFolder = (update?: boolean) => {
        setUpdateFolder(null);
    };
    //@ts-ignore
    const [treeItems, setTreeItems] = useState<ITaskTree[]>([]);
    useEffect(() => {
        // dispatch(getEntitiesDataTask({ ...filterState, projectId: id }));
        dispatch(getEntities({ ...filterState, projectId: id }));
    }, []);

    useEffect(() => {
        setTasks(initialState.dataTasks);
    }, [JSON.stringify(initialState.dataTasks)]);
    useEffect(() => {
        if (id) {
            dispatch(getEntity(id)).then((data: any) => {
                setProjectDetail(data.payload?.data);
            });
        }
    }, [state.updateEntitySuccess]);
    useEffect(() => {
        if (id) {
            dispatch(getEntity(id)).then((data: any) => {
                setProjectDetail(data.payload?.data);
            });
        }
    }, [state.createEntitySuccess]);

    useEffect(() => {
        if (Number(projectDetail?.projectId)) {
            dispatch(fetching());
            dispatch(
                getMembers({
                    ...stateMember.filterState,
                    projectId: Number(projectDetail?.projectId),
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectDetail?.projectId, JSON.stringify(stateMember.filterState)]);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            dispatch(fetching());
            dispatch(getEntities({ ...filterState, projectId: id }))
                .then((data: any) => {
                    setTasks(data.payload.data.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(true);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filterState), id]);

    useEffect(() => {
        if (state.updateEntitySuccess) {
            setIsLoading(true);
            dispatch(fetching());
            dispatch(getEntities({ ...filterState, projectId: id }))
                .then((data: any) => {
                    setTasks(data.payload.data.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(true);
                });
            dispatch(resetEntity());
        }
        if (state.updateEntitySuccess && !Boolean(detailTask)) {
            ToastSuccess("Cập nhật công việc thành công")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.updateEntitySuccess]);

    useEffect(() => {
        if (state.createEntitySuccess) {
            setIsLoading(true);
            dispatch(fetching());
            dispatch(getEntities({ ...filterState, projectId: id }))
                .then((data: any) => {
                    setTasks(data.payload.data.data);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(true);
                });
            dispatch(resetEntity());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.createEntitySuccess]);

    useEffect(() => {
        if (tasks && projectDetail) {
            setTreeItems(renderTaskTree(tasks, projectDetail));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        JSON.stringify(tasks),
        state.createEntitySuccess,
        state.updateEntitySuccess,
        JSON.stringify(projectDetail),
    ]);

    useEffect(() => {
        if (updateEntitySuccess || createEntitySuccess) {
            dispatch(resetEntity());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateEntitySuccess,createEntitySuccess]);

    const mapTaskTypeToIcon: { [key in TaskTypeEnum]: React.ReactNode } = {
        [TaskTypeEnum.FOLDER]: <FolderIcon className="tree-icon" />,
        [TaskTypeEnum.TASK]: <ClipboardIcon className="tree-icon" />,
    };

    const calculateDiffDay = (startDate: string, endDate: string) => {
        if (!startDate || !endDate) return 0;
        const start = dayjs(startDate);
        const end = dayjs(endDate);
        const diff = end.diff(start, "day");
        return diff.toFixed(2);
    };

    // calculate percentage = (total task done / total task) * 100

    /**
     * Gantt Chart
     */
    const taskFields: TaskFieldsModel = {
        id: "taskId",
        name: "name",
        startDate: "treeStartedDate",
        endDate: "treeEndDate",
        child: "children",
    };

    const timelineSettings: TimelineSettingsModel = {
        showTooltip: true,
        topTier: {
            unit: "Week",
            format: "W, dd/MM/yyyy",
            formatter: (date: any) => {
                const dayjsObj = dayjs(date);
                const week = dayjsObj.week();
                const dateFormat = dayjsObj.format("DD/MM/YYYY");

                return `Tuần ${week}, ${dateFormat}`;
            },
        },
        bottomTier: {
            unit: "Day",
            format: "EEE",
        },
        weekStartDay: 1,
    };

    const updateTaskStatus = (props: any, newStatus: SubTaskStatusEnum) => {
        dispatch(
            updateEntity({
                ...props.taskData,
                assignIds: [user?.id],
                status: newStatus,
            } as ITask)
        );
    };

    const customizeNameCollumn = (props: any) => {
        if (props?.taskData) {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setDetailTask(props?.taskData?.id);
                    }}
                >
                    <div
                        className="d-flex justify-content-start"
                        style={{ gap: "4px" }}
                    >
                        {props.taskData.id === taskIdNameDefault.TOPPROJECT ? (
                            <>
                                <HomeIcon className="tree-icon" />
                                <span className="info-text">
                                    {props.taskData?.taskId || ""}
                                </span>{" "}
                                {projectDetail?.name}
                            </>
                        ) : (
                            <>
                                <>
                                    {
                                        mapTaskTypeToIcon[
                                            props.taskData.type as TaskTypeEnum
                                        ]
                                    }
                                </>
                                <span className="info-text">
                                    {props.taskData?.taskId || ""}
                                </span>{" "}
                                {props?.taskData?.name}
                            </>
                        )}
                    </div>
                </span>
            );
        } else {
            return <></>;
        }
    };

    const customizeStatusCollumn = (props: any) => {
        if (props?.taskData) {
            return (
                <span>
                    <>
                        {(props?.taskData?.type as TaskTypeEnum) ===
                        TaskTypeEnum.TASK ? (
                            <StatusSelect
                                color={
                                    subMapTaskStatusToColor[
                                        props.taskData
                                            .status as SubTaskStatusEnum
                                    ]
                                }
                                options={subTaskStatusArray?.map((item) => ({
                                    value: item,
                                    label: subMapTaskStatusToString[item],
                                }))}
                                value={{
                                    value: props.taskData?.status,
                                    label: subMapTaskStatusToString[
                                        props.taskData
                                            .status as SubTaskStatusEnum
                                    ],
                                }}
                                minWidth="158px"
                                onChange={async (value) => {
                                    const newTaskId = props?.taskData?.taskId;
                                    setTaskId(newTaskId);
                                    onSelectTasId(newTaskId);

                                    if (
                                        value?.value ===
                                        SubTaskStatusEnum.REVIEW
                                    ) {
                                        const hasResults =
                                            await fetchAndCheckResults(
                                                newTaskId
                                            );
                                        if (hasResults) {
                                            updateTaskStatus(
                                                props,
                                                value?.value as SubTaskStatusEnum
                                            );
                                        }
                                    } else {
                                        updateTaskStatus(
                                            props,
                                            value?.value as SubTaskStatusEnum
                                        );
                                    }
                                }}
                            />
                        ) : (
                            <TaskProgress
                                height={6}
                                taskProgress={handleSubGetProgressBar(
                                    props.taskData,
                                    statusProgressArray
                                )}
                            />
                        )}
                    </>
                </span>
            );
        } else {
            return <span></span>;
        }
    };

    const customizeAssignCollumn = (props: any) => {
        if (props?.taskData) {
            if (props?.taskData?.id === "topProject") {
                const topLevelAssigns =
                    projectMembers?.map((child: any) => child.user || []) || [];

                return (
                    <span
                        style={{
                            display: "block",
                            marginBottom: "5px",
                            cursor: "pointer",
                        }}
                    >
                        <AvatarContainer
                            size={22}
                            userArray={topLevelAssigns}
                        />
                    </span>
                );
            } else {
                const userArray =
                    props?.taskData?.assignUsers?.[0]?.assigns?.map(
                        (item: any) => item.assign
                    ) ??
                    props?.taskDatas?.assigns?.map((item: any) => item.assign);
                return (
                    <span
                        style={{
                            display: "block",
                            marginBottom: "5px",
                            cursor: "pointer",
                        }}
                    >
                        <AvatarContainer size={22} userArray={userArray} />
                    </span>
                );
            }
        } else {
            return <span></span>;
        }
    };

    const customizeStartDateCollumn = (props: any) => {
        if (props?.taskData) {
            return (
                <>
                    {props?.taskData?.id === "topProject" ? (
                        <span>
                            {dayjs(projectDetail?.startedDate).format(
                                "DD/MM/YYYY"
                            )}
                        </span>
                    ) : (
                        <span>
                            {dayjs(
                                props?.taskData?.startedDate || undefined
                            ).format("DD/MM/YYYY")}
                        </span>
                    )}
                </>
            );
        } else {
            return <span></span>;
        }
    };

    const customizeEndDateCollumn = (props: any) => {
        if (props?.taskData) {
            return (
                <>
                    {props?.taskData?.id === "topProject" ? (
                        <span>
                            {dayjs(projectDetail?.endedDate).format(
                                "DD/MM/YYYY"
                            )}
                        </span>
                    ) : (
                        <span>
                            {dayjs(
                                props?.taskData?.endDate || undefined
                            ).format("DD/MM/YYYY")}
                        </span>
                    )}
                </>
            );
        } else {
            return <span></span>;
        }
    };

    const customizeDiffDateCollumn = (props: any) => {
        if (
            props?.taskData &&
            props.taskData.id !== taskIdNameDefault.TOPPROJECT
        ) {
            let actualProgress = 0;
            if (props?.taskData?.actualProgress) {
                actualProgress = props?.taskData?.actualProgress;
            }
            const date =
                Number(
                    calculateDiffDay(
                        props?.taskData?.startedDate || "",
                        props?.taskData?.endDate || ""
                    )
                ) - actualProgress;
            return <div>{date}</div>;
        } else {
            return <span></span>;
        }
    };

    const TaskbarTemplate = (props: any) => {
        // if (props?.taskData) {
        //     const { taskData } = props;
        //     const isTask = taskData.type === "TASK";

        //     const startDate = dayjs(taskData.startedDate);
        //     const endDate = dayjs(taskData.endDate);
        //     const currentDate = dayjs();
        //     let progress = 0;

        //     if (currentDate.isAfter(endDate)) {
        //         progress = 100;
        //     } else if (currentDate.isAfter(startDate)) {
        //         const totalDuration = endDate.diff(startDate);
        //         const elapsedDuration = currentDate.diff(startDate);
        //         progress = Math.round((elapsedDuration / totalDuration) * 100);
        //     }

        //     return (
        //         <div
        //             style={{ marginLeft: "15px" }}
        //             className={`gantt-taskbar-container ${
        //                 isTask ? "task-template" : "folder-template"
        //             }`}
        //         >
        //             <div className="e-gantt-child-taskbar-inner-div e-gantt-child-taskbar">
        //                 {isTask && (
        //                     <div
        //                         className="e-gantt-child-progressbar-inner-div e-gantt-child-progressbar"
        //                         style={{ width: `${progress}%` }}
        //                     ></div>
        //                 )}
        //             </div>
        //             <div
        //                 className="external-task-info"
        //                 style={{
        //                     position: "absolute",
        //                     left: "100%",
        //                     top: "50%",
        //                     transform: "translateY(-50%)",
        //                     marginLeft: "10px",
        //                     whiteSpace: "nowrap",
        //                     display: "flex",
        //                     alignItems: "center",
        //                     fontSize: "12px",
        //                     gap: "10px",
        //                 }}
        //             >
        //                 <span
        //                     style={{
        //                         fontWeight: "bold",
        //                     }}
        //                 >
        //                     {taskData.name}
        //                 </span>
        //             </div>
        //         </div>
        //     );
        // } else {
        //     return <div></div>;
        // }
        if (props?.taskData) {
            console.log(projectDetail)
            console.log(projectMembers)
            const { taskData } = props;
            const isTask = taskData.type === "TASK";

            const startDate = dayjs(taskData.startedDate);
            const endDate = dayjs(taskData.endDate);
            const currentDate = dayjs();
            let progress = 0;

            if (currentDate.isAfter(endDate)) {
                progress = 100;
            } else if (currentDate.isAfter(startDate)) {
                const totalDuration = endDate.diff(startDate);
                const elapsedDuration = currentDate.diff(startDate);
                progress = Math.round((elapsedDuration / totalDuration) * 100);
            }
            console.log(taskData)
            let topLevelAssigns;
            if (taskData?.id === "topProject") {
                topLevelAssigns =
                    projectMembers?.map((child: any) => child.user || []) || [];
            } else {
                topLevelAssigns =
                    taskData?.assignUsers?.[0]?.assigns?.map(
                        (item: any) => item.assign
                    ) ??
                    props.taskDatas?.assigns?.map((item: any) => item.assign);
            }
            
            return (
                <div
                    style={{ marginLeft: "15px" }}
                    className={`gantt-taskbar-container ${
                        isTask ? "task-template" : "folder-template"
                    }`}
                >
                    <div className="e-gantt-child-taskbar-inner-div e-gantt-child-taskbar">
                        {isTask && (
                            <div
                                className="e-gantt-child-progressbar-inner-div e-gantt-child-progressbar"
                                style={{ width: `${progress}%` }}
                            ></div>
                        )}
                    </div>
                    <div
                        className="external-task-info"
                        style={{
                            position: "absolute",
                            left: "100%",
                            top: "50%",
                            transform: "translateY(-50%)",
                            marginLeft: "10px",
                            whiteSpace: "nowrap",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            gap: "10px",
                        }}
                    >
                        <span
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {taskData.name}
                        </span>
                        <span
                            style={{
                                display: "block",
                                marginBottom: "5px",
                                cursor: "pointer",
                            }}
                        >
                            <AvatarContainer size={22} userArray={topLevelAssigns} />
                        </span>
                        <span>
                            {startDate.format("DD/MM/YYYY")} -{" "}
                            {endDate.format("DD/MM/YYYY")}
                        </span>
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    };

    return (
        <>
            <div className="page-outlet-content">
                <div className="table-context">
                    <div>
                        <CDropdown className="dropdown-custom ">
                            <CDropdownToggle className="btn-custom primary-500 btn-xs">
                                {/* <CButton onClick={handleCreateTask}> */}
                                <PlusIcon /> Tạo
                                {/* </CButton> */}
                            </CDropdownToggle>
                            <CDropdownMenu className="dropdown-menu">
                                <CDropdownItem
                                    onClick={handleCreateFolder}
                                    style={{ cursor: "pointer" }}
                                >
                                    <FolderIcon className="dropdown-icon" /> Thư
                                    mục mới
                                </CDropdownItem>
                                <CDropdownItem
                                    onClick={handleCreateTask}
                                    style={{ cursor: "pointer" }}
                                >
                                    <ClipboardIcon className="dropdown-icon" />{" "}
                                    Công việc mới
                                </CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                        <CustomDropDownButton
                            getEntities={getEntities}
                            setFilterState={setFilterState}
                            filterState={filterState}
                            activeMode={activeSortMode}
                            projectId={id}
                            setMode={setSortMode}
                            listMode={[
                                {
                                    name: "Ngày tạo - Giảm dần",
                                    value: "DESC",
                                    field: "createdDate",
                                },
                                {
                                    name: "Ngày tạo - Tăng dần",
                                    value: "ASC",
                                    field: "createdDate",
                                },
                                {
                                    name: "Ngày bắt đầu - Giảm dần",
                                    value: "DESC",
                                    field: "startedDate",
                                },
                                {
                                    name: "Ngày bắt đầu - Tăng dần",
                                    value: "ASC",
                                    field: "startedDate",
                                },
                            ]}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "28px",
                        }}
                    >
                        <div className="custom-form">
                            <CFormSwitch
                                size="xl"
                                label="Timeline"
                                checked={viewSetting === "Default"}
                                onChange={(e) =>
                                    setViewSetting(
                                        e.currentTarget.checked
                                            ? "Default"
                                            : "Grid"
                                    )
                                }
                            />
                        </div>
                        <div
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                padding: "7px 8px",
                                gap: "4px",
                            }}
                        >
                            <img
                                src={iconFiltter}
                                style={{ width: "16px", height: "16px" }}
                                alt=""
                            />
                            <span
                                style={{
                                    display: "block",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                    color: "#364152",
                                }}
                            >
                                Lọc
                            </span>
                        </div>
                    </div>
                </div>

                {projectDetail && !isLoading && treeItems && (
                    <div className="">
                        <GanttComponent
                            // id="Default"
                            dataSource={treeItems}
                            treeColumnIndex={1}
                            // rowSelected={(e) => console.log(e, e.data.taskData, 'selectedRow')}
                            queryCellInfo={(args) => {
                                if (args?.column?.field === "diffDate") {
                                    if (args?.data[args.column.field] > 0) {
                                        args.cell.classList.add("green-time")
                                    } else if (args.data[args.column.field] < 0) {
                                        args.cell.classList.add("red-time")
                                    } else {

                                    }
                                }
                            }}
                            cellSelected={(e) => {
                                if (
                                    e?.cellIndex &&
                                    e?.cellIndex?.cellIndex === 1
                                ) {
                                    // const taskType = e.data.taskData.type;
                                    setDetailTask(e.data.taskData.id);
                                    // if (taskType === TaskTypeEnum.TASK) {
                                    // setUpdateTask(e.data.taskData);
                                    // } else if (taskType === TaskTypeEnum.FOLDER) {
                                    //   setUpdateFolder(e.data.taskData);
                                    // }
                                }
                            }}
                            // timezone='ICT'
                            taskFields={taskFields}
                            allowSelection={true}
                            // labelSettings={labelSettings}
                            height="720px"
                            projectStartDate={
                                projectDetail?.startedDate
                                    ? dayjs(projectDetail.startedDate).toDate()
                                    : undefined
                            }
                            projectEndDate={dayjs().endOf("year").toDate()}
                            timelineSettings={timelineSettings}
                            splitterSettings={{
                                view: viewSetting,
                                columnIndex: 3,
                                position: "50%",
                            }}
                            taskbarHeight={44}
                            taskbarTemplate={TaskbarTemplate}
                            className="custom-tree"
                            locale="vi"
                            taskMode="Manual"
                            selectionSettings={{ mode: "Both" }}
                        >
                            <ColumnsDirective>
                                <ColumnDirective
                                    field="taskId"
                                    width="80"
                                    visible={false}
                                ></ColumnDirective>
                                <ColumnDirective
                                    field="name"
                                    headerText="Task"
                                    width="245"
                                    minWidth="245"
                                    // clipMode="EllipsisWithTooltip"
                                    template={customizeNameCollumn}
                                ></ColumnDirective>
                                <ColumnDirective
                                    field="status"
                                    headerText="Trạng thái"
                                    width="190"
                                    minWidth={190}
                                    template={customizeStatusCollumn}
                                ></ColumnDirective>
                                <ColumnDirective
                                    field="assign"
                                    headerText="Giao cho"
                                    width="205"
                                    minWidth={205}
                                    maxWidth={205}
                                    template={customizeAssignCollumn}
                                ></ColumnDirective>
                                <ColumnDirective
                                    field="treeStartedDate"
                                    headerText="Ngày bắt đầu"
                                    template={customizeStartDateCollumn}
                                ></ColumnDirective>
                                <ColumnDirective
                                    field="treeEndDate"
                                    headerText="Ngày kết thúc"
                                    template={customizeEndDateCollumn}
                                ></ColumnDirective>
                                <ColumnDirective
                                    field="diffDate"
                                    headerText="+/- ngày"
                                    template={customizeDiffDateCollumn}
                                ></ColumnDirective>
                            </ColumnsDirective>
                            <EventMarkersDirective>
                                <EventMarkerDirective
                                    day={currentDate}
                                    label="Hôm nay"
                                ></EventMarkerDirective>
                            </EventMarkersDirective>
                            {/* <Inject services={[Filter, Sort]} /> */}
                        </GanttComponent>
                    </div>
                )}

                {detailTask && id && projectDetail && treeItems ? (
                    <TaskDetailModal
                        projectMembers={projectMembers}
                        isMyTask={true}
                        setCreateTask={setCreateTask}
                        treeItemArr={treeItems}
                        project={projectDetail}
                        chosenTaskId={detailTask}
                        visible={Boolean(detailTask)}
                        setVisible={handleCancelsetDetailTask}
                    />
                ) : (
                    ""
                )}

                {createTask && id && projectDetail ? (
                    <TaskUpdate
                        project={projectDetail}
                        taskType={TaskTypeEnum.TASK}
                        visible={createTask}
                        setVisible={setCreateTask}
                        detailTask={detailTask ?? ""}
                    />
                ) : (
                    ""
                )}
                {updateTask && id && projectDetail ? (
                    <TaskUpdate
                        project={projectDetail}
                        taskType={TaskTypeEnum.TASK}
                        visible={Boolean(updateTask)}
                        taskObj={updateTask}
                        setVisible={handleCancelUpdateTask}
                    />
                ) : (
                    ""
                )}
                {createFolder && id && projectDetail ? (
                    <TaskUpdate
                        project={projectDetail}
                        taskType={TaskTypeEnum.FOLDER}
                        visible={createFolder}
                        setVisible={setCreateFolder}
                        detailTask={detailTask ?? ""}
                    />
                ) : (
                    ""
                )}
                {updateFolder && id && projectDetail ? (
                    <TaskUpdate
                        project={projectDetail}
                        taskType={TaskTypeEnum.FOLDER}
                        visible={Boolean(updateFolder)}
                        taskObj={updateFolder}
                        setVisible={handleCancelUpdateFolder}
                    />
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default TasksManagementGantt;
