import { SYNCFUSION_COMMUNITY_KEY } from "@/shared/config/constants";
import { socket } from "@/shared/config/socket";
import { dayjsSetup } from "@/shared/utils/dayjs.helper";
import { loadCldr, registerLicense } from "@syncfusion/ej2-base";
import { memo, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { RootState } from "../../../reducers";
import { publicRoutes } from "../../../routes";
import { getProfile } from "../../modules/auth/auth.api";
import { fetching } from "../../modules/auth/auth.reducer";

import * as gregorian from "cldr-data/main/vi/ca-gregorian.json";
import * as numbers from "cldr-data/main/vi/numbers.json";
import * as timeZoneNames from "cldr-data/main/vi/timeZoneNames.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";

registerLicense(SYNCFUSION_COMMUNITY_KEY);
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContainer = () => {
  dayjsSetup();
  const routeRender = createHashRouter(publicRoutes);
  const { darkMode } = useSelector((state: RootState) => state.container);

  const dispatch = useDispatch<any>();
  const { token } = useSelector((state: RootState) => state.authentication);

  useEffect(() => {
    let tempToken = token;
    if (!tempToken) {
      tempToken = localStorage.getItem("authentication_token");
    }

    if (tempToken) {
      dispatch(fetching());
      dispatch(getProfile());
      socket.io.opts.extraHeaders = {
        token: tempToken,
      };
      socket.disconnect().connect();
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className={darkMode ? "dark-theme" : "light-theme"}>
      <ToastContainer
        position={toast.POSITION.TOP_LEFT}
        className="toastify-container"
        toastClassName="toastify-toast"
      />
      <Suspense fallback={loading}>
        <RouterProvider router={routeRender} />
      </Suspense>
    </div>
  );
};

export default memo(TheContainer);
